<template>
  <div>
    <navigation msg="自定义技能编辑"></navigation>
    <div class="btn2" @click="submit">保存</div>
    <div class="email">
      <input v-model="jn" type="email" placeholder="请输入您的技能">
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import navigation from "../../components/navigation"
export default {
  components:{
    navigation
  },
  data(){
    return {
      jn:""
    }
  },
  methods:{
    submit(){
      if(this.jn == ""){
        Toast("请输入技能")
        return
      }
      let { id,} = this.$route.query
      let user_id = sessionStorage.getItem("id")
      this.$http.post("/user/v1/Position/positionDetail",{reqType:"positionDetail",user_id:user_id,position_name:this.jn,id:id})
      .then((res)=>{
        res = JSON.parse(res.data)
        if(res.code == 0){
          Toast.success(res.msg)
          setTimeout(()=>{
            this.$router.push("./onlineTag")
          },1000)
          // console.log(res)
        }
      })
      .catch((r)=>{console.log(r)})
    } 
  }
}
</script>
<style scoped>
.btn2{
  position:absolute;
  top:0.2rem;
  right:0.2rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.email{
  height:1.13rem;
  line-height: 1.13rem;
  background: #FFFFFF;
  padding-left:0.49rem;
}
.email input{
  border:none;
  font-size:0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #848484;
line-height: 0.48rem;
height: 0.48rem;
}
</style>